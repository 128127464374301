import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
import { BentoCard } from "Components/uiElements/UIComponents/bento-grid/bento-grid";
import "./TourList.css";
import {
  GridContainer,
  scrollToTop,
  SkeletonTourListPreload,
} from "MainApp/assets/visual/styles/globalStyles/globalJsxStyles";
import { ToursContext } from "MainApp/assets/utils/context/TourContext";
import { slugify } from "MainApp/assets/utils/js/slugyfy";
import { AuthContext } from "MainApp/assets/utils/context/AuthContext";

function TourList() {
  const { tours, setTours, setLoading } = useContext(ToursContext);
  const getListUrl = "/toursAPI/get-list";
  const { token, axiosInstance, isAuthReady, realToken } =
    useContext(AuthContext);
  // const axiosInstance = useAxios();
  useEffect(() => {
    // console.log("👉 axiosInstance en TourList:", axiosInstance);
    if (axiosInstance) {
      // ¿Tiene método .get?
      // console.log("👉 Tipo de axiosInstance.get:", typeof axiosInstance.get);
    }
  }, [axiosInstance]);
  useEffect(() => {
    // if (!isAuthReady) {
    //   console.log("Empty! isAuthReady");
    //   return;
    // } // No hacer nada si aún no sabemos si hay token
    // if (!token) {
    //   console.log("Empty! token");
    //   return;
    // } // No hacer nada si aún no sabemos si hay token
    // console.log("TourList useEffect ->", {
    //   toursLength: tours.length,
    //   isAuthReady,
    //   token,
    //   axiosInstance,
    // });
    if (tours.length === 0 && isAuthReady && token && axiosInstance) {
      // console.log("inside!");
      // console.log("VALUE ", token);
      // console.log(
      //   "VALUE of the instance ",
      //   axiosInstance.defaults.headers.common.Authorization
      // );
      axiosInstance
        .get(getListUrl)
        .then((response) => {
          // console.log("then");
          if (response.data && Array.isArray(response.data.tours)) {
            // console.log("Tours fetched successfully:", response.data.tours);
            const transformedTours = response.data.tours.map((tour) => {
              // const tourTitle = tour.name;
              console.log(tour)
              // const tourSlug = slugify(tourTitle);

              return {
                id: tour.tourUUID,
                title: tour.name,
                slug: tour.slug,
                description: tour.description,
                images:
                  tour.cover_image_uri.startsWith("http://") ||
                  tour.cover_image_uri.startsWith("https://")
                    ? tour.cover_image_uri
                    : `${process.env.REACT_APP_API_BASE_URL}${tour.cover_image_uri}`,
                price: tour.priceMin,
                sprice: tour.stripe_price_id,
                duration: tour.duration,
              };
            });
            setTours(transformedTours);
          } else {
            console.error(
              "API response does not contain an array of tours:",
              response.data
            );
          }
        })
        .catch((error) => {
          // console.log("catch");
          console.error("There was an error fetching the tours!", error);
        })
        .finally(() => {
          // console.log("finally");
          setLoading(false);
        });
    }
    // console.log("realToken");
    // console.log(realToken);
    // console.log("tours");
    // console.log(tours);
  }, [
    tours,
    isAuthReady,
    token,
    axiosInstance,
    getListUrl,
    setTours,
    setLoading,
  ]);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  scrollToTop();
  return (
    <div className="bodySelectTourList">
      <>
        {!imageLoaded && <SkeletonTourListPreload />}
        <GridContainer>
          {Array.isArray(tours) &&
            tours.length > 0 &&
            tours.map((tour, index) => {
              // (
              // console.log("TourList -> tour:", tour);
              return (
                <BentoCard
                  slug={tour.slug}
                  key={tour.id}
                  tourState={tour}
                  index={index}
                  {...tour}
                  onImagesLoad={handleImageLoad}
                  imageLoaded={imageLoaded}
                />
              );
              // )
            })}
        </GridContainer>
      </>
    </div>
  );
}

export default TourList;
