// src/pages/TourDetails.js

import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PSpinner } from "@porsche-design-system/components-react";
// import { slugify } from "../../assets/utils/js/slugyfy"; // Asegúrate de que esta función esté correctamente implementada
import { setItem, getItem } from "../../assets/utils/js/storage.js"; // Importa las funciones de almacenamiento
import "./TourInformation.css";
import InformationDescriptionSide from "Components/InformationFolder/InformationDescriptionSide/InformationDescriptionSide";
import InformationBookSide from "Components/InformationFolder/InformationBookSide/InformationBookSide";
import { AuthContext } from "MainApp/assets/utils/context/AuthContext.js";
import { ToursContext } from "MainApp/assets/utils/context/TourContext.jsx";

const TourDetails = ({ isPhone }) => {
  const { axiosInstance } = useContext(AuthContext);

  const { slug } = useParams(); // Extrae el 'slug' desde los parámetros de la URL
  const [tour, setTour] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Estilos condicionales basados en si es un dispositivo móvil o no
  const tourInformationStyling = {
    display: isPhone ? "flex" : "grid",
  };
  const { getTourBySlug } = useContext(ToursContext);

  useEffect(() => {
    let mounted = true; // Variable para evitar actualizaciones de estado si el componente se desmonta
    const cachedTour = getTourBySlug(slug);
    if (cachedTour) {
      setTour(cachedTour);
      setLoading(false);
    } else {
      const fetchTour = async () => {
        try {
          // Solicitar al endpoint público para obtener los detalles del tour
          const response = await axiosInstance.get(`/tour/details/${slug}`);

          if (response.data) {
            const tourFromServer = response.data;
            // console.log(tourFromServer);
            // Construir el objeto del tour con todos los campos necesarios
            const tourData = {
              id: tourFromServer.tourUUID,
              title: tourFromServer.name,
              slug: tourFromServer.slug, // Utilizar el slug proporcionado por el backend
              price: tourFromServer.priceMin,
              duration: tourFromServer.duration,
              sprice: tourFromServer.stripe_price_id,
              images:
                tourFromServer.cover_image_uri.startsWith("http://") ||
                tourFromServer.cover_image_uri.startsWith("https://")
                  ? tourFromServer.cover_image_uri
                  : `${process.env.REACT_APP_API_BASE_URL}${tourFromServer.cover_image_uri}`,
              description: tourFromServer.description,
              // Añadir otros campos según sea necesario
            };

            // Almacenar en la caché
            await setItem(`tour_${slug}`, tourData);

            if (mounted) {
              setTour(tourData);
              setLoading(false);
            }
          } else {
            if (mounted) {
              navigate("/404"); // Redirigir a la página 404 si no se encuentra el tour
            }
          }
        } catch (err) {
          console.error("Error fetching tour:", err);
          if (mounted) {
            setError("Tour no encontrado.");
            setLoading(false);
            // Opcional: Redirigir a la página 404
            // navigate('/404');
          }
        }
      };

      fetchTour();

      return () => {
        mounted = false; // Limpiar la variable al desmontar el componente
      };
    }
  }, [slug, navigate, axiosInstance, getTourBySlug]);

  if (loading) {
    // Mostrar un spinner mientras se carga la información del tour
    return (
      <div className="spinnerContainerInfo">
        <PSpinner />
      </div>
    );
  }

  if (error) {
    return <div className="errorMessage">{error}</div>;
  }

  if (!tour) {
    return <div>No hay datos del tour disponibles.</div>;
  }

  return (
    <div className="tourInformation" style={tourInformationStyling}>
      <InformationDescriptionSide tour={tour} isPhone={isPhone} />
      {!isPhone && <InformationBookSide tour={tour} />}
    </div>
  );
};

export default TourDetails;
